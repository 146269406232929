import { useMutation, gql } from '@apollo/client'
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Heading,
	useColorModeValue,
	FormErrorMessage,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { FC } from 'react'

import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'

import { LoginUser } from './__generated__/LoginUser'

/***
 *
 * Login Query
 *
 ***/
const loginUser = gql`
	mutation LoginUser($creds: UsersPermissionsLoginInput!) {
		login(input: $creds) {
			user {
				id
				username
				email
			}
		}
	}
`

/***
 *
 * Login View Component
 *
 ***/
interface FormProps {
	identifier: string
	password: string
}

const LoginView: FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<FormProps>()

	const [login, { loading }] = useMutation<LoginUser>(loginUser, {
		onError: error => onError(error, toast),
	})
	const toast = useToast()

	const submitHandler = async (formData: FormProps) => {
		const { errors } = await login({ variables: { creds: formData } })
		!errors && (window.location.pathname = '/')
	}

	return (
		<Flex minH='100vh' justify='center' bg={useColorModeValue('gray.100', 'gray.800')} pt={12}>
			<Stack spacing={8} mx='auto' minW='md' py={12} px={6}>
				<Stack align='center'>
					<Heading color='gray.800' fontSize='4xl'>
						Log in
					</Heading>
				</Stack>
				<Box rounded='lg' bg={useColorModeValue('white', 'gray.700')} boxShadow='lg' p={8}>
					<Stack spacing={4} as='form' onSubmit={handleSubmit(submitHandler)}>
						<FormControl id='email' isInvalid={!!errors?.identifier}>
							<FormLabel>Username or Email</FormLabel>
							<Input type='email' {...register('identifier', { required: true })} />
							{!!errors?.identifier && (
								<FormErrorMessage>Username or Email is required</FormErrorMessage>
							)}
						</FormControl>
						<FormControl id='password' isInvalid={!!errors?.password}>
							<FormLabel>Password</FormLabel>
							<Input type='password' {...register('password', { required: true })} />
							{!!errors?.password && <FormErrorMessage>Password is required</FormErrorMessage>}
						</FormControl>
						<Stack spacing={10}>
							<Button
								isLoading={loading || isSubmitting}
								bg='gray.800'
								color='primary'
								_hover={{
									bg: 'gray.800',
								}}
								type='submit'
							>
								Sign in
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	)
}

export default LoginView
