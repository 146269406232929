import { FC } from 'react'

import { withPage } from '~/components/page'
import LoginView from '~/views/login'

const Login: FC = () => {
	return <LoginView />
}

export default withPage(Login, {
	public: true,
	name: 'Log In',
})
